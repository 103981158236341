.title {
  color: var(--primary-color);
  text-align: center;
}

@media print {
  .header {
    display: none;
  }
}
