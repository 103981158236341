.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  min-width: 300px;
}

.label {
  font-size: 12px;
}
