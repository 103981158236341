.container {
  padding: 32px;
  padding-top: 0;
}

.editorPreviewContainer {
  display: flex;
  justify-content: center;
}

.editorContainer {
  max-height: 80vh;
  max-width: 40vw;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 32px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.previewContainer {
  flex: 1;
  margin-left: 32px;
}

@media only screen and (max-width: 1080px) {
  .editorPreviewContainer {
    flex-direction: column;
  }
  .container {
    padding: 8px;
  }
  .editorContainer {
    padding: 8px;
    max-height: unset;
    max-width: unset;
    overflow: auto;
  }
  .previewContainer {
    margin-left: 0;
    margin-top: 16px;
  }
}

@media print {
  .editorContainer {
    display: none;
  }
}
