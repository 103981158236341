.container {
  background: violet;
  font:
    12px "Times New Roman",
    Times,
    sans-serif;
  line-height: 1.4;
  padding: 32px;
  border-radius: 4px;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
}

.summary {
  margin: 0;
}

.resume {
  margin: 0 auto;
  min-width: 480px;
  max-width: 720px;
  padding: 40px 60px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px #aaa;
}

.sectionBlock {
  display: flex;
  width: 100%;
}

.sectionName {
  width: 18%;
  vertical-align: top;
  display: inline-block;
}

.sectionContent {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 80%;
  vertical-align: top;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sectionListItems {
  max-width: 45%;
  margin-right: 16px;
}

.title {
  font-weight: bold;
}

.date {
  float: right;
  margin-left: 8px;
}

.separator {
  height: 14px;
}

.sectionLine {
  border-style: dashed;
  border-width: 1px;
  border-color: #cfcfcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.skillBlock {
  margin-bottom: 4px;
}

/* Media Queries */
@media only screen and (max-width: 1080px) {
  .container {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .resume {
    margin: 0 auto;
    padding: 0.5em 1em;
    border: none;
    overflow-x: scroll;
  }
  .sectionContent {
    width: 100%;
  }
  .sectionContent .date {
    padding-right: 2em;
  }
  .sectionName {
    width: auto;
    margin-right: 4px;
  }
}

@media print {
  .container {
    min-height: unset;
    max-height: unset;
    overflow-y: unset;
    padding: 0;
    border: none;
  }
  .resume {
    display: block;
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
  }
}
