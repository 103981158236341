.title {
  color: var(--primary-color);
  margin: 0;
  text-align: center;
}

.formSection {
  border: 0;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.formSectionTitle {
  font-weight: bold;
  padding-top: 48px;
  margin-bottom: 8px;
  color: var(--primary-color);
  background-color: transparent;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.themeSelector {
  padding: 6px;
  border-radius: 4px;
  border: 0.5px solid var(--border-color);
  background: none;
}

.actions {
  display: flex;
  align-items: center;
}

.autosave {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.autosaveInput {
  margin: 0 4px 0 0;
}

.sectionCard {
  border-radius: 4px;
  padding: 8px;
  border: 0.5px solid var(--border-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.deleteButton {
  align-self: flex-end;
}

.addButton {
  align-self: flex-end;
}
