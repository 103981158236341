.homeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeContainerCard {
  padding: 32px;
  border-radius: 4px;
  width: 400px;
  background-color: var(--complimentary-bg-color);
}

.resumeForm {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
  margin-right: 8px;
}

.resumeList {
  padding: 0;
  margin-top: 16px;
  list-style: none;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listTitle {
  flex: 1;
}

.listButton {
  padding-right: 0;
  text-align: right;
}

.emptyPlaceholder {
  margin: 32px 0;
  color: var(--sub-text-color);
}
