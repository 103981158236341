.container {
  position: relative;
}

.print {
  position: absolute;
  top: 16px;
  left: 16px;
}

@media print {
  .print {
    display: none;
  }
}
