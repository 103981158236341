.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.label {
  font-size: 12px;
}

.textarea {
  min-width: 300px;
  resize: none;
}
