body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  --bg-color: #ffffff;
  --complimentary-bg-color: #edede9;
  --primary-color: #212529;
  --color: #000000;
  --danger-color: #c9184a;
  --complimentary-color: #ffffff;
  --sub-text-color: #8d99ae;
  --border-color: #ced4da;
  --disabled-bg: #5f6469;
  background-color: var(--bg-color);
}

body * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:visited {
  color: unset;
}

button {
  background-color: var(--primary-color);
  color: var(--complimentary-color);
  border: 0;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: unset;
}
button:active {
  outline: none;
}
button:disabled {
  background-color: var(--disabled-bg);
  cursor: not-allowed;
}
button.link {
  background-color: transparent;
  color: var(--primary-color);
  text-decoration: underline;
}
button.link.danger {
  color: var(--danger-color);
}

input,
textarea {
  padding: 6px;
  border-radius: 4px;
  border: 0.5px solid var(--border-color);
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.row > * {
  flex: 1;
}
.row > :not(:last-child) {
  margin-right: 8px;
}
